<template>
  <div class="pad-2">
    <div class="bb-container bb-container--lista">
      <div class="ml-filters">
        <ListEmpty v-if="show" :showConstructorLink="false" />
        <div v-else>
          <div v-if="!productsIsNotEmpty">
            {{ $t("mylist.noFilterResult") }}
          </div>

          <div
            class="list-section list-section--owner"
            v-for="(group, index) in productGroups"
            :key="index"
          >
            <div
              :ref="'div_accordion_' + group[0].mainCategoryId"
              @click="toggle(group[0].mainCategoryId)"
              class="bb-accordion mar-b-1 pointer"
            >
              <div class="bb-accordion__title">
                <h3 class="header-3 mb-0">
                  {{ group[0].mainCategory }}
                  <small>({{ group.length }})</small>
                </h3>
              </div>

              <div v-if="!filteredByCategories" class="bb-accordion__right">
                <img
                  :ref="'img_accordion_' + group[0].mainCategoryId"
                  src="../../../assets/img/ico/ico-chevron-up.svg"
                  width="16px"
                  height="16px"
                  class="bb-accordion__chevron bb-accordion__chevron--active"
                  alt
                />
              </div>
            </div>

            <div v-if="productsIsNotEmpty" :ref="group[0].mainCategoryId" class="">
              <template>
                <div v-for="(product, index) in group" :key="index">
                  <ListProductCard
                    class="mb-3"
                    :productData="product"
                    :contributionsEnabled="
                      list.stripeAccountId != null && list.stripeAccountId != ''
                    "
                    :index="incrementProductIndex()"
                    @deleted="showDeleteProductAlert"
                    :clickable="false"
                    :logoUrl="logoUrl"
                  />

                  <InFeedAd
                    v-if="
                      howManyProductsTotal >= 5 &&
                      !loggedInUserIsPremium &&
                      shouldDisplayAdAfterItem(index)
                    "
                  />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import CanGetCategories from "../../../Mixins/CanGetCategories";

import InFeedAd from "../../adsense/InFeedAd.vue";

import ListProductCard from "./ListProductCard";
import ListEmpty from "./ListEmpty";

export default {
  name: "List",
  props: {
    logoUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  mixins: [CanGetCategories],
  components: {
    InFeedAd,
    ListEmpty,
    ListProductCard,
  },
  computed: {
    ...mapGetters({
      list: "ownerList",
      ownerProductFilters: "ownerProductFilters",
      showAlertKey: "showAlertKey",
      showAlert: "showAlert",
      loggedInUserIsPremium: "loggedInUserIsPremium",
    }),
    show() {
      return this.list?.products?.length === 0;
    },
    showAlertLocal() {
      return this.showAlertKey === this.key && this.showAlert;
    },
    key() {
      return "deleted_product_dialog" + this.list.id;
    },
    filteredByCategories() {
      if (this.ownerProductFilters) {
        const found = this.ownerProductFilters?.find((filter) => {
          return filter.name === "category";
        });
        if (found) return true;
      }
      return false;
    },
    productsIsNotEmpty() {
      return this.filteredProducts && this.filteredProducts.length > 0;
    },
    productGroups() {
      if (this.filteredProducts.length != 0) {
        return this.filteredProducts.reduce(function (r, a) {
          r[a.mainCategory] = r[a.mainCategory] || [];
          r[a.mainCategory].push(a);
          return r;
        }, Object.create(null));
      }
      return [];
    },
    filteredProducts() {
      const products = (this.list.products || [])
        .map((product) => this.productWithRetailer(product, this.$route.query.retailer))
        .filter((product) => !!product)
        .filter((product) => product.isFree)
        .map((product) => ({
          ...product,

          mainCategory:
            this.getTranslatedCategory(product.mainCategoryId) || product.mainCategory,
        }));

      return products;
    },
    howManyProductsTotal() {
      return this.filteredProducts.length;
    },
  },
  methods: {
    incrementProductIndex() {
      const previousProductIndex = this.productIndex;
      this.productIndex++;
      return previousProductIndex;
    },
    isOpen(id) {
      let div = this.$refs[id];
      if (div) {
        return !div[0].hidden;
      }
      return false;
    },
    toggle(id) {
      let div = this.$refs[id];
      let div_accordion = this.$refs["div_accordion_" + id];
      let img_accordion = this.$refs["img_accordion_" + id];
      if (!this.isOpen(id)) {
        div[0].hidden = false;
        div_accordion[0].classList.add("bb-accordionn--open");
        img_accordion[0].classList.add("bb-accordion__chevron--active");
        return;
      }
      div_accordion[0].classList.remove("bb-accordionn--open");
      img_accordion[0].classList.remove("bb-accordion__chevron--active");
      div[0].hidden = true;
    },
    remove() {
      this.showModal = false;
    },
    showDeleteProductAlert() {
      this.$alert.success(this.$t("mylist.removeSuccess"), this.key);
    },
    shouldDisplayAdAfterItem(listItemIndex) {
      let i = 1 + listItemIndex;
      function advance(n) {
        i -= n;
      }

      advance(2);
      if (i === 0) {
        return true;
      }

      advance(3);
      if (i === 0) {
        return true;
      }

      do {
        advance(5);
      } while (i > 0);

      return i === 0;
    },
    sameRetailer(a, b) {
      if (typeof a !== "string" && typeof a !== "object") {
        return false;
      }
      if (typeof b !== "string" && typeof b !== "object") {
        return false;
      }
      if (!a || !b) {
        return false;
      }

      const aName = typeof a === "string" ? a : a.name;
      const bName = typeof b === "string" ? b : b.name;

      return aName.toLowerCase() === bName.toLowerCase();
    },
    productWithRetailer(product, retailer) {
      const hasRetailer =
        this.sameRetailer(product.retailerName, retailer) ||
        product.productRetailers.some((productRetailer) =>
          this.sameRetailer(productRetailer, retailer)
        );
      if (!hasRetailer) {
        return null;
      }

      return {
        ...product,
        retailerName: retailer,
        productRetailers: product.productRetailers.filter((productRetailer) =>
          this.sameRetailer(productRetailer, retailer)
        ),
      };
    },
  },
  created() {
    this.productIndex = 0;
  },
  mounted() {
    const alertMessage = localStorage.getItem("alertMessage");
    const alertType = localStorage.getItem("alertType") || "success";

    if (alertMessage) {
      if (alertType == "error") this.$alert.error(alertMessage, false, this.key);
      else this.$alert.success(alertMessage, this.key);
      localStorage.removeItem("alertMessage");
      localStorage.removeItem("alertType");
    }
  },
};
</script>
